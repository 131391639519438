/** @format */

import React, { FC, useEffect, useState } from "react";
import {
	BoxEvent,
	LoadingPage,
	ModalCheckMobile,
	ModalConfirmPolicy,
	ModalCreateBodyShape,
	ModalEventSuccess,
	ModalNotiMaintain,
	ModalResultBMI,
	ProcessGlobal,
} from "components";
import {
	useDetectMultiTab,
	useDevice,
	useLayout,
	useLoginOneDevice,
	useMaintain,
	useStoreBodyShape,
	useUser,
	useWindowDimensions,
} from "hooks";
import i18next from "i18next";
import { MainLayout } from "components";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { privateRoutes, RouterType } from "./routes";
import NotFound from "pages/404/Notfound";
import { EModalBodyShape, ENotification } from "types";
import { LANGUAGES } from "utils";
import { Maintain } from "pages/maintain";

const RequireAuth: FC<{ children: React.ReactElement }> = ({ children }) => {
	return <>{children}</>;
};

function App() {
	const { deviceId, countryCode } = useDevice();
	const { languages, user, isLogged, setLanguages } = useUser();
	const { showModalCreate, setShowModelCreate, dataResult } =
		useStoreBodyShape();
	const { setIsConfirmTerm, isConfirmTerm, openModalWarn, setOpenModalWarn } =
		useLayout();
	const { width } = useWindowDimensions();
	useLoginOneDevice(deviceId);
	useDetectMultiTab();
	useMaintain();

	const [searchParams] = useSearchParams();
	const referralCode = searchParams.get("_referralCode");
	const refSrc = searchParams.get("refSrc");

	useEffect(() => {
		referralCode && localStorage.setItem("referralCode", referralCode);
		refSrc && localStorage.setItem("refSrc", refSrc);
	}, [referralCode, refSrc]);

	useEffect(() => {
		if (countryCode === "vn") return setLanguages(LANGUAGES[2]);
		if (countryCode === "kr") return setLanguages(LANGUAGES[1]);
		return setLanguages(LANGUAGES[0]);
	}, [countryCode]);

	useEffect(() => {
		if (languages.value) {
			i18next.changeLanguage(languages.value);
		}
	}, [languages.value]);

	useEffect(() => {
		if (!user?.isAgree) {
			setIsConfirmTerm(true);
		} else {
			setIsConfirmTerm(false);
		}
	}, [user?.isAgree]);

	const [openSuccess, setOpenSuccess] = useState(false);
	const isFirstLogin = localStorage.getItem("firstLogin");
	useEffect(() => {
		!isFirstLogin &&
			isLogged &&
			user?.daysRemaining === 14 &&
			setOpenSuccess(true);
	}, [isFirstLogin, isLogged, user?.daysRemaining]);

	return (
		<React.Suspense fallback={<LoadingPage />}>
			<ProcessGlobal />
			<BoxEvent />
			{openSuccess && (
				<ModalEventSuccess
					open={openSuccess}
					onClose={() => {
						localStorage.setItem("firstLogin", "true");
						setOpenSuccess(false);
					}}
				/>
			)}
			{isConfirmTerm && isLogged && (
				<ModalConfirmPolicy
					open={isConfirmTerm}
					onClose={() => setIsConfirmTerm(false)}
				/>
			)}

			{showModalCreate === EModalBodyShape.RESULT && (
				<ModalResultBMI
					open={true}
					onClose={() => {
						setShowModelCreate(EModalBodyShape.DEFAULT);
					}}
					goBack={() => {
						setShowModelCreate(EModalBodyShape.CREATE);
					}}
					dataResult={dataResult}
				/>
			)}
			{showModalCreate === EModalBodyShape.CREATE && (
				<ModalCreateBodyShape
					open={true}
					onClose={() => setShowModelCreate(EModalBodyShape.DEFAULT)}
					onOpenResult={() => setShowModelCreate(EModalBodyShape.RESULT)}
				/>
			)}
			{openModalWarn === ENotification.MAINTAIN && (
				<ModalNotiMaintain
					onClose={() => setOpenModalWarn(ENotification.DEFAULT)}
					open={openModalWarn === ENotification.MAINTAIN}
				/>
			)}
			{width < 768 && <ModalCheckMobile />}
			<Routes>
				{privateRoutes.map((route: RouterType, index) => {
					const Layout = route.layout || MainLayout;
					const Component = route.component;
					return (
						<Route
							key={`${index}-${route.path}`}
							path={route.path}
							element={
								<RequireAuth>
									<Layout>
										<Component />
									</Layout>
								</RequireAuth>
							}
						/>
					);
				})}
				<Route path={"*"} element={<NotFound />} />
				<Route path={"/maintain"} element={<Maintain />} />
			</Routes>
		</React.Suspense>
	);
}

export default App;
