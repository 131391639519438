/** @format */

import React from "react";
import { Icons } from "assets";
import {
	Box,
	FormControl,
	MenuItem,
	Tooltip,
	tooltipClasses,
	TooltipProps,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styled from "@emotion/styled";
import { TextCus } from "components/TextCus";
import { mainColor } from "theme";
import { useTranslation } from "react-i18next";

interface CommonSelectProps {
	options: { value: string; name: string }[];
	value: string | null;
	onChange: (event: SelectChangeEvent<any>) => void;
	width?: string;
	label?: string;
	titleTooltip?: string;
	placeholder?: string;
}

const CustomSelect = styled(Select)(() => ({
	backgroundColor: "#F5F5F5",
	borderRadius: "10px",
	zIndex: "999",
	height: "40px",
	"& .MuiSelect-select.MuiSelect-standard.MuiInputBase-input.MuiInput-input": {
		paddingRight: "12px !important",
		zIndex: 1,
	},
	"& .MuiInputBase-root.MuiInput-root.MuiInput-underline": {
		"::before": {
			content: "none",
		},
		"::after": {
			content: "none",
		},
	},
	"& .MuiFormLabel-root.MuiInputLabel-root": {
		display: "none !important",
	},
	"& .MuiSelect-icon": {
		color: "#3F51B5",
	},
	"& .MuiOutlinedInput-notchedOutline": {
		borderColor: "transparent",
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		borderColor: "transparent",
	},
	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		borderColor: "transparent",
	},
	padding: "10px 10px",
}));

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(() => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#ccc",
		color: mainColor.secondary,
		boxShadow: "white",
		fontSize: 11,
		maxWidth: "150px",
	},
}));

const SelectCus = ({
	options,
	value,
	onChange,
	width,
	label,
	titleTooltip,
	placeholder,
	...selectProps
}: CommonSelectProps) => {
	const { t } = useTranslation();
	return (
		<LightTooltip title={titleTooltip} placement="top">
			<FormControl fullWidth variant="outlined">
				{label && (
					<Box display={"flex"} alignItems={"start"} mb={"5px"}>
						<TextCus fontSize={"13px"} fontWeight={"400"}>
							{label}
						</TextCus>
						<TextCus color={"red"} ml={"2px"}>
							*
						</TextCus>
					</Box>
				)}
				<CustomSelect
					placeholder={placeholder}
					sx={{ width: width ?? "100%" }}
					IconComponent={() => (
						<Box
							sx={{
								pointerEvents: "inherit",
								display: "flex",
								alignItems: "center",
								position: "absolute",
								width: "100%",
								justifyContent: "space-between",
								padding: "10px",
								cursor: "pointer",
								left: 0,
							}}>
							{!value ? <TextCus color={"#A3A3A3"}>Select</TextCus> : <Box />}
							<Icons.ArrowSelect />
						</Box>
					)}
					value={value}
					variant="standard"
					onChange={onChange}
					{...selectProps}>
					{options.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{t(option.name)}
						</MenuItem>
					))}
				</CustomSelect>
			</FormControl>
		</LightTooltip>
	);
};

export default SelectCus;
