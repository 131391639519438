import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Divider } from "@mui/material";
import { useClothes, useOutfitStore } from "hooks";
import { TParams } from "types";
import { EDropType } from "types/outfit";
import ListImage from "./ListImage";
import Heading from "./Heading";
import { SelectCus, TextCus } from "components";
import { CATEGORY, SIZE_BODY } from "utils";
import { Icons } from "assets";

export const ModelItems: React.FC = () => {
	// ======================= HOOKS ==============================
	const {
		setSelectedFileClothes,
		setImageClothes,
		selectedFileClothes,
		valueCategory,
		valueSize,
		setValueCategory,
		setValueSize,
		setCurrentStep,
	} = useOutfitStore();
	const { useGetClothes } = useClothes();

	// ======================== STATE ========================
	const [paramsOutfit, setParamsOutfit] = useState<TParams>({
		page: 1,
		limit: 3,
		typeOutfit: valueCategory ?? "UPPER",
	});
	const {
		clothes: dataOutfit,
		isLoading,
		isFetchingNextPage,
		fetchNextPage,
	} = useGetClothes(paramsOutfit);

	useEffect(() => {
		setParamsOutfit({
			...paramsOutfit,
			typeOutfit: valueCategory ?? "UPPER",
		});
	}, [valueCategory]);

	return (
		<Box display={"flex"} flexDirection={"column"} gap={"15px"}>
			<Box
				display={"flex"}
				alignItems={"center"}
				gap={"15px"}
				justifyContent={"space-between"}>
				<SelectCus
					onChange={(e) => setValueCategory(e.target.value)}
					options={CATEGORY}
					value={valueCategory}
					label="Category"
					titleTooltip="Select the body part this item should cover"
				/>
				<SelectCus
					onChange={(e) => setValueSize(e.target.value)}
					options={SIZE_BODY}
					value={valueSize}
					label="Length"
					titleTooltip="Select the body length this item should cover"
				/>
			</Box>
			<Divider />
			<Heading title="Preset Items" />

			<ListImage
				isLoading={isLoading}
				images={dataOutfit}
				selectedImage={selectedFileClothes as string}
				setSelectedImage={(it) => {
					setSelectedFileClothes(it.id as string);
					setImageClothes(it.image as string);
					setCurrentStep(2);
				}}
				dropType={EDropType.OUTFIT}
			/>
			<Box
				component={"div"}
				onClick={() => fetchNextPage()}
				width={"fit-content"}
				sx={{ cursor: "pointer" }}
				margin={"0 auto"}
				padding={"4px 10px"}
				borderRadius={"12px"}
				bgcolor={"rgba(149, 119, 217, 0.2)"}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				gap={"15px"}>
				<TextCus>See more</TextCus>
				{isFetchingNextPage ? (
					<CircularProgress size={15} />
				) : (
					<Icons.ArrowSelect />
				)}
			</Box>
		</Box>
	);
};
